<template>
  <section>
    <div>
      <v-breadcrumbs large :items="breadcrumbsItems"></v-breadcrumbs>
    </div>
    <v-row>
      <v-col cols="12" sm="12" md="8" lg="8">
        <DetalleConvenio :convenio="agreement_detail" />
      </v-col>
    </v-row>
    <v-row>
      <v-tabs background-color="transparent">
        <v-tabs-slider color="secondary"></v-tabs-slider>
        <v-tab>
          <v-icon class="mx-2">mdi-clipboard-text</v-icon> Etapa Actual
        </v-tab>
        <v-tab-item>
          <v-row align="center">
            <v-col cols="6">
              <p class="mt-4 text-h6 secondary--text">
                Recepción de ofertas
              </p>
            </v-col>
            <v-col cols="6">
              <v-row>
                <v-col cols="6">
                  <p>
                    Fecha de Inicio:
                    {{
                      moment(currentPhase?.fecha_inicio).format("DD/MM/YYYY")
                    }}
                  </p>
                </v-col>
                <v-col cols="6">
                  <p>
                    Fecha de Fin:
                    {{
                      moment(currentPhase?.fecha_fin).format("DD/MM/YYYY")
                    }}
                  </p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="6" lg="2" class="py-0 my-0">
              <p class="text-h6">Tiempo restante</p>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="2" class="py-0 my-0">
              <div class="justify-left">
                <CronometroComponent />
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="py-0 my-0">
              <p class="text-h6 mb-0">
                Durante este período los proveedores cargarán sus ofertas, las
                cuales no podrán abrirse hasta la siguiente etapa
              </p>
            </v-col>
          </v-row>

          <!-- botones atras guardar seleccion-->

          <v-row>
            <v-col cols="8" sm="3" md="4" lg="4">
              <v-btn class="ma-2" outlined @click="regresa()"> Volver </v-btn>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs>
    </v-row>
  </section>
</template>

<script>
import moment from "moment-timezone";
import DetalleConvenio from "../../components/DetalleConvenioComponent.vue";
import CronometroComponent from "./components/CronometroComponent.vue";
import { mapState } from "vuex";
export default {
  name: "recepcionOfertasView",
  components: {
    DetalleConvenio,
    CronometroComponent,
  },

  data: () => ({}),
  computed: {
    ...mapState("convenioMarcoAdmin", ["agreement_detail", "breadcrumbsItems"]),
    currentPhase() {
      if (this.agreement_detail?.fase?.length > 0) {
        return this.agreement_detail.fase[0];
      } else return {};
    },
  },
  methods: {
    regresa() {
      this.$router.push("/convenio-marco-list");
    },
  },
  created() {
    if (this.breadcrumbsItems.length > 1) {
      this.breadcrumbsItems.pop();
    }
    this.breadcrumbsItems.push({
      text: this.agreement_detail.correlativo,
    });
  },
};
</script>
<style lang="scss" scoped>
:deep(.theme--light.v-tabs-items) {
  background: transparent !important;
}
</style>