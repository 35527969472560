<template>
  <v-chip color="secondary" dark label>
    {{ time.h <= 9 ? "0" + time.h : "" + time.h }}:
    {{ time.m <= 9 ? "0" + time.m : "" + time.m }} :
    {{ time.s <= 9 ? "0" + time.s : "" + time.s }}
  </v-chip>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "CronometroComponent",
  data: () => ({
    time: {
      h: 0,
      m: 0,
      s: 0,
    },
    intervalFunc: null,
  }),
  computed: {
    ...mapState("convenioMarcoAdmin", ["agreement_detail"]),
  },
  methods: {
    startCronometro() {
      let countDownDate = new Date(
        this.agreement_detail.fase[0].fecha_fin
      ).getTime();

      this.intervalFunc = setInterval(() => {
        let now = new Date().getTime();
        let timeLeft = countDownDate - now;
        if (timeLeft > 0) {
          this.time.h = Math.floor(
            (timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          );
          this.time.m = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
          this.time.s = Math.floor((timeLeft % (1000 * 60)) / 1000);
        }
      }, 1000);
    },
  },
  mounted() {
    this.startCronometro();
  },
  beforeDestroy() {
    clearInterval(this.intervalFunc);
  },
};
</script>
